<template>
  <div>
    <Menu />
    <section id="banner" class="my-5">
       <div class="container">
        <div class="row justify-content-center g-0">
            <div class="col-12">
              <div class="banner-section porfolio-banner">
                <img src = "/images/lifestyle/lifestyle-img1.png" class="w-100" />
                <h1 class="text-start">TERMS AND CONDITIONS</h1>
                <div class="social-links switch-bg-dblue">
                <div class="row">
                  <div class="col-3"><a href="https://www.facebook.com/ChasEverittProperty/" target="_blank"><img src="/images/icons/facebook.png" class="mw-100" width="20px" /></a></div>
                  <div class="col-3"><a href="https://www.linkedin.com/company/chas-everitt/?trk=top_nav_home" target="_blank"><img src="/images/icons/linkedin.png" class="mw-100" width="20px" /></a></div>
                  <div class="col-3"><a href="https://www.instagram.com/chaseveritt/" target="_blank"><img src="/images/icons/instagram.png" class="mw-100" width="20px" /></a></div>
                  <div class="col-3"><a href="https://www.youtube.com/@ChasEverittSA" target="_blank"><img src="/images/icons/youtube.png" class="mw-100" width="20px" /></a></div>
                </div>
              </div>
              </div>
          </div>
        </div>
       </div>
    </section>

    <section>
      <div class="wrapper py-5">
        <div class="container">
        <div class="row">
          <div class="col-12">
            <p>Lorem ipsum dolor sit amet. Eos dolores tempora hic voluptates voluptatem eum quam dolor in eligendi omnis. Sit odit fuga non repellat ipsa ut molestiae perspiciatis ea repellendus quia id vero ipsam ea atque mollitia. 33 possimus sequi in optio voluptas et quidem eaque et officiis modi qui quaerat ipsa est consequatur dignissimos.</p>

            <p>Lorem ipsum dolor sit amet. Eos dolores tempora hic voluptates voluptatem eum quam dolor in eligendi omnis. Sit odit fuga non repellat ipsa ut molestiae perspiciatis ea repellendus quia id vero ipsam ea atque mollitia. 33 possimus sequi in optio voluptas et quidem eaque et officiis modi qui quaerat ipsa est consequatur dignissimos.</p>

            <p>Lorem ipsum dolor sit amet. Eos dolores tempora hic voluptates voluptatem eum quam dolor in eligendi omnis. Sit odit fuga non repellat ipsa ut molestiae perspiciatis ea repellendus quia id vero ipsam ea atque mollitia. 33 possimus sequi in optio voluptas et quidem eaque et officiis modi qui quaerat ipsa est consequatur dignissimos.</p>
          </div>
        </div>
      </div>
      </div>
    </section>
    <SeoLinks />
    <Footer />
  </div>
</template>
<script>
import Menu from '@/components/home/Menu.vue'
import Footer from '@/components/home/Footer.vue'
import SeoLinks from '@/components/home/SeoLinks.vue'
export default {
  components: {
    Menu, Footer, SeoLinks
  },
  data() {
    return {
      data: {

      }
    }
  },
}
</script>]